$msg-data-row-data-flex: 4;
$msg-data-row-label-flex: 1;

$default-layout-step: 8px;

$page-header-height: 68px;
$page-header-height-long: 168px;

$footer-height: 48px;
$footer-height-long: 88px;

$toolbar-height: 64px;

$body-margin: 8px;

$gap-to-main: calc(#{$body-margin} + #{$footer-height} + #{$toolbar-height});
$gap-to-main-long: calc(#{$body-margin} + #{$footer-height-long} + #{$toolbar-height});
