// Palette Generator http://mcg.mbitson.com/

@use '@angular/material' as mat;
$primary-palette: (
  50 : #f4e3e8,
  100 : #e3b9c6,
  200 : #d08aa0,
  300 : #bd5b7a,
  400 : #ae375e,
  500 : #a01441,
  600 : #98123b,
  700 : #8e0e32,
  800 : #840b2a,
  900 : #73061c,
  A100 : #ffa2b0,
  A200 : #ff6f85,
  A400 : #ff3c5a,
  A700 : #ff2344,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$accent-palette: (
  50 : #eeeeee,
  100 : #d4d4d4,
  200 : #b7b7b7,
  300 : #9a9a9a,
  400 : #858585,
  500 : #6f6f6f,
  600 : #676767,
  700 : #5c5c5c,
  800 : #525252,
  900 : #404040,
  A100 : #f5a8a8,
  A200 : #f07a7a,
  A400 : #ff3838,
  A700 : #ff1f1f,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

/**
 * Base theming import
 */
@import '@angular/material/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$app-primary-color: mat.define-palette($primary-palette,500);
$app-accent-color: mat.define-palette($accent-palette,500);

$app-warn-color: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary-color, $app-accent-color, $app-warn-color);

$primary: map-get($app-theme, primary);
$accent: map-get($app-theme, accent);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);
