@import 'src/scss/1_settings/color-constants';
@import 'src/scss/1_settings/layout-constants';
@import 'src/scss/1_settings/breakpoints';

.page-header {
  // layout
  position: sticky;
  top: 0;
  z-index: 4;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  // skin
  background-color: $msg-color-main-background-light;
  height: $page-header-height;

  @include breakpoint-max(840px) {
    height: $page-header-height-long;
    flex-flow: row wrap;
  }

  &__detail {
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__required {
    margin-right: 24px;
    margin-bottom: 0;
  }
  // breadcrumbs an sich fehlen noch, hier kommt aber auch der zurück Button in der mobilen Ansicht rein daher, schon erstellt.
  &__breardcrumbs{
    margin-left: 10px;
    flex: 0 0 max-content;
    display: inline-flex;
    justify-content: flex-end !important;
  }

  &__heading {
    margin-left: 10px;
    flex: 0 0 max-content;
    display: flex;
    align-items: center;

    @include breakpoint-max(840px) {
      flex: 1 0 50%;
    }

    &--fix-width {
      flex: 0 0 300px;
    }


    > h1 {
      font-size: 26px;
    }


    > button {
      margin: 0 10px;
      z-index: 5 !important;
      padding: 0 !important;
      min-width: 40px !important;
      max-width: 40px !important;
      min-height: 40px;
      border: 1px solid $msg-color-main; //!important;

      &:hover {
        // margin: 8px !important;
        border: 2px solid; //!important;
        background-color: $msg-color-main-background-light;
        color: $msg-color-main;

      }

    }

    > .helpBtn {
      width: 40px;
      height: 40px;
      border: none;
      margin: 0 4px;

      &:active {
        background-color: $msg-color-main-background-light;
      }

      &:focus-within {
        .mat-mdc-button-persistent-ripple::before {
          display: none;
        }
      }
    }
  }

  &__middle {
    flex: 1 1 auto;

    @include breakpoint-max(840px) {
      flex: 1 0 50%;
      display: flex;
      justify-content: center;
    }

    @include breakpoint-max(551px) {
      order: +1;
      margin-top: 50px;
    }
  }

  &__actions {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-right: 16px;

    @include breakpoint-max(840px) {
      flex: 1 0 50%;
      display: flex;
      justify-content: center;
    }
  }
}
