h1 {
  font-family: 'Akkurat regular', sans-serif;
  display: inline-block;
  font-size: 1.6em;
}
h2 {
  font-family: 'Akkurat regular', sans-serif;
  font-size: 1.5em;
}
h3 {
  font-family: 'Akkurat regular', sans-serif;
  font-size: 1.4em;
}
h4 {
  font-family: 'Akkurat regular', sans-serif;
  font-size: 1.3em;
}
h5 {
  font-family: 'Akkurat regular', sans-serif;
  font-size: 1.2em;
}
h6 {
  font-family: 'Akkurat regular', sans-serif;
  font-size: 1.1em;
}

th {
  font-family: 'Akkurat Bold', sans-serif;
  font-size: 18px !important;
}

td {
  font-family: 'Akkurat light', sans-serif;
  font-size: 16px !important;
}

.mat-mdc-paginator-container {
  font-family: 'Akkurat light', sans-serif;
  font-size: 16px !important;
}

.mat-raised-button {
  font-size: 16px;
  font-family: 'Akkurat Bold', sans-serif;
}

.mdc-tab__content {
  font-family: 'Akkurat Bold', sans-serif;
  font-size: 1.5em;
}

.testMethodContentTitle { // scss - class naming -- Nr. 38
  font-family: 'Akkurat Bold', sans-serif;
}

.accent {
  font-family: 'Akkurat regular', sans-serif;
}

.secondLevelTab { // scss - class naming -- Nr. 39
  .mdc-tab__content {
    font-size: 15px !important;
  }
}

.thirdLevelTab { // scss - class naming -- Nr. 40
  .mdc-tab__content {
    font-size: 12px !important;
  }
}

span {
  font-family: 'Akkurat light', sans-serif;
  font-size: 16px !important;
}

.testdesignmethod-tab {
  .mdc-tab__content {
    font-size: 16px !important;
  }
}

/*.mjx-mrow {
  span:not(.MJXc-TeX-size2-R) {
    font-family: 'Akkurat bold' !important;
  }
}*/

.regular {
  font-family: 'Akkurat regular', sans-serif;
}
.bold {
  font-family: 'Akkurat Bold', sans-serif;
}

p {
  font-family: 'Akkurat light', sans-serif;
}

.noValueInput {
  font-style: italic !important;
}
