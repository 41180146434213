@use '@angular/material' as mat;
@import 'src/scss/1_settings/global-constants';

@media screen {
  .mat-sidenav {
    border-radius: 4px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .mat-sidenav-content {
    padding: 0;
    background-color: $msg-color-main-background-light;

    .mat-mdc-button-focus-overlay {
      background-color: transparent !important;
    }
  }


  .mat-sidenav-container--lean {
    height: calc(100vh - 70px - 48px) !important;
    overflow: hidden !important;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: $msg-color-gray-02;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: $msg-color-gray;
      border-radius: 4px;
    }

    &help {
      margin: 0 20px;

      >mat-card:first-of-type {
        min-height: calc(100vh - 210px);
        height: calc(100vh - 210px);
        overflow-x: auto;
      }
    }

    // TODO Vereinheitlichen
    &balm {
      > div:first-of-type {
        padding-top: 0;
        min-height: calc(100vh - 200px);
        @include mat.elevation(0);
      }

      overflow: hidden !important;
    }

    // TODO Vereinheitlichen
    &ten {
      >mat-card:first-of-type {
        min-height: calc(100vh - 225px);
        height: calc(100vh - 225px);
        overflow-x: auto;
        //border: $default-border;
        @include mat.elevation(0);
      }
    }

    // TODO Vereinheitlichen
    &catalog {
      mat-card:first-of-type {
        min-height: calc(100vh - 230px);
        height: calc(100vh - 230px);
        overflow: auto;
        //border: $default-border;
        @include mat.elevation(0);
      }
    }

  }

  .mat-sidenav--fixed-height {
    position: fixed !important;
    top: 121px !important;
    overflow-y: auto !important;
    margin-top: 20px;
    padding-top: 16px;
    height: calc(100vh - 187px);
    border: $no-border !important;
    @include mat.elevation(0);

    div.mat-drawer-inner-container {
      width: 420px;
      background-color: $msg-color-main-background-light;
    }
  }

  .mat-sidenav--evaluation-steps {
    position: fixed !important;
    top: 200px !important;
    overflow-y: auto !important;
    margin-top: 20px;
    padding-top: 16px;
    height: calc(100vh - 265px);
    border: $no-border !important;
    @include mat.elevation(0);

    div.mat-drawer-inner-container {
      width: 420px;
      background-color: $msg-color-main-background-light;
    }
  }

  .mat-sidenav--evaluation-steps {
    position: fixed !important;
    top: 128px !important;
    overflow-y: auto !important;
    margin-top: 20px;
    padding-top: 16px;
    height: calc(100vh - 196px);
    border: $no-border !important;
    @include mat.elevation(0);

    div.mat-drawer-inner-container {
      width: 420px;
      background-color: white;
    }
  }
}

@media screen and (max-width: $large-tablet) {
  .mat-sidenav-container--lean {
    height: calc(100vh - 70px - 88px) !important;
    overflow: hidden !important;
  }
}
