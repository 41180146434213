@import '../1_settings/color-constants';
@import '../1_settings/global-constants';

.mdc-button {
  height: 40px;
}

.mat-mdc-unelevated-button.mat-mdc-button-base, .mdc-button--outlined.mat-mdc-button-base {
  height: 40px;
}

.mdc-button__label {
  letter-spacing: normal;

  > span {
    letter-spacing: normal;
  }
}

// Normaler Button
.mat-mdc-unelevated-button {
  margin: 10px !important;
  color: $msg-color-main-text-light;
  background-color: $msg-color-main;
}

.mat-mdc-unelevated-button .mat-icon {
  font-size: 24px !important;
}

.mat-mdc-unelevated-button:disabled {
  margin: 8px !important;
  border: 2px solid $msg-color-gray-03 !important;
  background-color: $msg-color-gray-03 !important;
  color: $msg-color-main-text-light !important;
}

.mat-mdc-unelevated-button:hover,
.mat-mdc-unelevated-button:focus-visible {
  margin: 8px !important;
  border: 2px solid !important;
  background-color: $msg-color-main-background-light !important;;
  color: $msg-color-main !important;;
}

.mat-mdc-unelevated-button:active {
  outline: none !important;
}

// Primary flat Button
.mat-mdc-unelevated-button-primary {
  margin: 8px !important;
  border: 2px solid $msg-color-main !important;
  background-color: $msg-color-main !important;
  color: $msg-color-main-text-light !important;
}

.mat-mdc-unelevated-button-primary:hover {
  margin: 8px !important;
  border: 2px solid !important;
  background-color: $msg-color-main-background-light;
  color: $msg-color-main;
}

.mat-mdc-unelevated-button-primary:focus-visible {
  margin: 8px !important;
  border: 2px solid !important;
  color: $msg-color-main-text-light;
  background-color: $msg-color-main;
  border-color: $msg-color-main !important;

  outline-style: solid;
  outline-width: 2px;
  outline-color: $msg-color-main;
  outline-offset: 5px;
}

.mat-mdc-unelevated-button-primary:active {
  outline: none !important;
}

.mat-mdc-unelevated-button-primary:disabled {
  border: 2px solid $msg-color-gray-03 !important;
}

// Secondary flat Button
.mat-mdc-unelevated-button-secondary {
  margin: 8px !important;
  border: 2px solid $msg-color-main !important;
  color: $msg-color-main;
  background-color: $msg-color-main-background-light;

  .mat-mdc-button-persistent-ripple::before {
    display: none;
  }

  .mdc-button__label {
    color: $msg-color-main;
  }

  .mat-icon {
    color: $msg-color-main;
  }
}

.mat-mdc-unelevated-button-secondary:hover {
  margin: 8px !important;
  background-color: $msg-color-main !important;
  color: $msg-color-main-text-light !important;
  border: 2px solid $msg-color-main !important;

  .mdc-button__label {
    color: $msg-color-main-text-light !important;
  }

  .mat-icon {
    color: $msg-color-main-text-light !important;
  }
}

.mat-mdc-unelevated-button-secondary:focus-visible {
  margin: 8px !important;
  border: 2px solid !important;
  color: $msg-color-main !important;
  background-color: $msg-color-main-background-light !important;
  border-color: $msg-color-main !important;

  outline-style: solid;
  outline-width: 2px;
  outline-color: $msg-color-main;
  outline-offset: 5px;

  .mdc-button__label {
    color: $msg-color-main !important;
    //background-color: $msg-color-main-background-light;
  }

  .mat-icon {
    color: $msg-color-main !important;
  }
}

.mat-mdc-unelevated-button-secondary:active {
  outline: none;
}

.mat-mdc-unelevated-button-secondary:disabled {
  border: 2px solid $msg-color-gray !important;
  background-color: $msg-color-main-background-light !important;

  .mdc-button__label {
    color: $msg-color-gray !important;
  }

  .mat-icon {
    color: $msg-color-gray !important;
  }
}

// Tertiary Button
.mat-mdc-unelevated-button-tertiary {
  margin: 8px !important;
  border: 2px solid $msg-color-gray-03 !important;
  color: $msg-color-main;
  background-color: $msg-color-main-background-light;
  border-color: $msg-color-gray-03 !important;

  outline-style: solid;
  outline-width: 2px;
  outline-color: $msg-color-main;
  outline-offset: 5px;

  .mat-mdc-button-persistent-ripple::before {
    display: none;
  }

  .mdc-button__label {
    color: $msg-color-main;
  }

  .mat-icon {
    color: $msg-color-main;
  }
}

.mat-mdc-unelevated-button-tertiary:active {
  outline: none;
}

.mat-mdc-unelevated-button-tertiary:disabled {
  border: 2px solid $msg-color-gray-03 !important;
}

.mat-mdc-unelevated-button:hover, .mat-mdc-unelevated-button:focus {
  background-color: white;
  color: $msg-color-main;
  border: 1px solid $msg-color-main;
}

.mat-mdc-unelevated-button-tertiary:hover {
  margin: 8px !important;
  background-color: $msg-color-main !important;
  color: $msg-color-main-text-light;
  border: 2px solid $msg-color-main !important;

  .mdc-button__label {
    color: $msg-color-main-text-light;
  }

  .mat-icon {
    color: $msg-color-main-text-light;
  }
}

.mat-mdc-unelevated-button-tertiary:focus-visible {
  margin: 8px !important;
  border: 2px solid !important;
  color: $msg-color-main;
  background-color: $msg-color-main-background-light;
  border-color: $msg-color-gray-03 !important;

  outline-style: solid;
  outline-width: 2px;
  outline-color: $msg-color-main;
  outline-offset: 5px;
}

.cancelButton {
  // scss - class naming -- Nr. 16
  width: 150px;
  background-color: $msg-color-gray-03;
  color: $msg-color-main-text-dark;
}

.cancelButton:hover,
.cancelButton:focus-visible {
  // scss - class naming -- Nr. 16
  border: 2px solid !important;
  background-color: $msg-color-main-background-light;
  color: $msg-color-gray;
}

.go-back-button {
  border: 0.5px solid $msg-color-gray !important;
  margin-right: 10px;
}

.addNfr-button {
  float: right;
}

.cancelSaveButton {
  // scss - class naming -- Nr. 22
  width: 150px;
}

.cancelSaveButton:hover,
.cancelSaveButton:focus-visible {
  // scss - class naming -- Nr. 22
  margin-left: 10px !important;
  margin-right: 10px !important;
  width: 150px;
}


.metricButtons {
  margin-left: 15px;
  margin-bottom: 15px;
}

.metricButtonGroup {
  background-color: transparent;
  border: transparent;
  margin-top: 10px;
}

.metricButton {
  margin-left: 5px;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  padding: 0 10px !important;
  min-width: 150px;
  margin-top: 5px !important;
  border: 2px solid $msg-color-main !important;
  color: $msg-color-main !important;
  height: 30px;
  line-height: 30px;

  &:hover {
    background-color: $msg-color-main !important;
    color: $msg-color-main-text-light !important;
  }

  &:focus {
    outline-style: solid;
    outline-width: 3px;
    outline-color: $msg-color-main;
    outline-offset: 10px;
  }

  &.cdk-keyboard-focused {
    background-color: $msg-color-main !important;
    color: $msg-color-main-text-light !important;
  }
}

.metricButton--highlighted {
  border: 2px solid $msg-color-state-info !important;
  color: $msg-color-state-info !important;
  font-weight: bold;
}

.helpButton {
  position: absolute !important;

  .mat-mdc-button-persistent-ripple::before {
    display: none;
  }
}

.helpButtonIcon {
  color: $msg-color-main;
}

mat-button-toggle-group {
  border-radius: 5px !important;
  padding: 2px;
  flex-wrap: wrap;
}

mat-button-toggle {
  border-radius: 5px;
}

.applicationsToggleButton {
  // scss - class naming -- Nr. 9
  width: 70px !important;
  height: 25px !important;


  &:hover {
    color: $msg-color-main !important;
  }
}

.applicationsToggleButtonText {
  // scss - class naming -- Nr. 10
  text-align: center;
}

.applicationsTableButton {
  // scss - class naming -- Nr. 14
  width: 150px;

}

.buttonContainer {
  float: right;
}

.mat-button-toggle {
  padding: 0;
}

.buttonRight {
  justify-content: flex-end;
  display: flex;
  padding: 0 24px 10px 0;
}

.dialogButtonBox {
  // scss - class naming -- Nr. 29
  display: grid;
  grid-gap: 15px;
  width: 100%;
}

.dialogButtonTop {
  // scss - class naming -- Nr. 30
  grid-row-start: 1;
  grid-row-end: 1;
  float: right;
  width: 97%;
}

.dialogButtonBottom {
  // scss - class naming -- Nr. 31
  grid-row-start: 2;
  grid-row-end: 2;
  float: right;
}

mat-button-toggle-group {
  background-color: $msg-color-gray-03;
  padding: 5px;
}

.mat-button-toggle {
  color: $msg-color-main-text-dark;
  border: 2px solid transparent;
  background-color: transparent;
}

.mat-button-toggle-checked {
  color: $msg-color-main-text-light !important;
  background-color: $msg-color-main;
  border: 2px solid $msg-color-main !important;
}

.mat-button-toggle:hover {
  color: $msg-color-main;
  background-color: $msg-color-main-background-light;
}

.mat-button-toggle:focus {
  outline-style: solid !important;
  outline-width: 3px !important;
  outline-color: $msg-color-main !important;
  outline-offset: 5px !important;

}

.applicationsToggleButton .mat-button-toggle-button:focus-visible {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: $msg-color-main !important;
  outline-offset: 5px !important;
  border-radius: 2px;

  background-color: $msg-color-main-text-light;
  color: $msg-color-main;
}

.mat-button-toggle-label-content {
  line-height: 24px !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 2px !important;
}

.mat-button-toggle-startseite {
  margin-left: 8px;
}

.mat-button-toggle-focus-overlay {
  background-color: transparent !important;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.deleteButton {
  padding: 0 !important;
  margin-right: 4px;

  &, &:focus-visible {
    border: none !important;
  }

  &:hover:enabled, &:focus:enabled {
    border: 1px solid $msg-color-main-border-dark;
  }
}

.helpIcon:focus-visible {
  outline-style: solid;
  outline-width: 2px;
  outline-color: $msg-color-main;
  outline-offset: 0;
}

.mdc-button .mat-icon {
  /*  margin: 0 4px !important;
    overflow: unset !important;
    display: inline-block !important;
    position: relative !important;
    vertical-align: top !important;
    font-size: 1.125rem !important;*/
  height: 26px !important;
  width: 26px !important;
}

.mdc-button .icon-no-margin {
  margin: 0 !important;
}
