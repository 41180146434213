.mat-menu-panel {
  min-width: 150px !important;
  max-width: 350px !important;
}

.custom-tooltip {
  width: max-content !important;
  max-width: max-content !important;
  font-family: 'Akkurat light', sans-serif;
  font-size: 16px !important;
  background-color: white;
  color: black !important;
}

mat-card-subtitle{
  font-family: "Akkurat Light", sans-serif;
}
