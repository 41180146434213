@import '../1_settings/color-constants';
@import '../1_settings/global-constants';
@import '../1_settings/layout-constants';
@import './colors';

.searchInput { // scss - class naming -- Nr. 1
  width: 100%;

  mat-icon {
    font-size: 25px !important;
  }
}

table {
  width: 100%;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

td {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.icon-table { // scss - class naming -- Nr. 13
  text-align: center;
  margin-top: -10px;
}


mat-form-field {
  width: 35%;
  padding-right: 5px;
}

mat-form-field{
  &.mat-form-group.mat-Pruef-toggled{
    width: 300px;
    position: fixed;
    top: 80px;
    right: 420px;
    z-index: 100;
  }
  &.mat-form-group.mat-Pruef-nontoggled{
    width: 410px;
    margin-left: 10px;
    position: fixed;
    top: 145px;
    z-index: 100;
  }

}

.toggled-Pruef{
  position: fixed;
  width: 410px !important;
  margin-left: 25px;
  margin-top: -3px;
}

.lowered-bitv-content{
  margin-top: 75px !important;
}


.inputFieldShort { // scss - class naming -- Nr. 32
  width: 120px;
}

.toggle-text-for-recomendations {
  font-family: "Akkurat light", sans-serif;
}

.enum-description {
  padding-bottom: 10px;
}

.testdesign {
  margin: 0;
  padding-inline-start: 15px;
  padding: 0;
}

.enum-lower-alpha {
  counter-reset: lower-alpha-counter;
  list-style: none;
  padding-left: 15px;
}

.enum-lower-alpha > li {
  list-style: none;
  padding-top: 10px;
}

.enum-lower-alpha > li:before {
  content: counter(lower-alpha-counter, lower-alpha) ")";
  counter-increment: lower-alpha-counter;
}

.enum-lower-roman {
  counter-reset: lower-roman-counter;
  list-style: none;
  padding-left: 15px;
}

.enum-lower-roman > li {
  list-style: none;
  padding-top: 10px;
}

.enum-lower-roman > li:before {
  content: counter(lower-roman-counter, lower-roman) ")";
  counter-increment: lower-roman-counter;
}

ol li::before {
  display: inline-block;
  width: 25px;
  margin-left: -25px;
  padding-left: -10px;
}

ol li {
  padding-inline-start: 5px;
}


.breakWithPadding {
  padding-top: 15px;
  display: block;
}

.inputFieldLong { // scss - class naming -- Nr. 68
  width: 90%;
}

.noHover {
  pointer-events: none !important;
}

.itemName:first-of-type {
  color: $msg-color-main;
  font-size: 25px;
  font-weight: bold;
}

.mat-drawer-container {
  overflow: visible !important;
}

.mat-drawer-content {
  height: max-content !important;
}

// .grid-list-nfa{
//   border: crimson !important;
// }
.stakeholderSelect1 {
  text-align: center;
  margin: 0 auto;
}

.stakeholderSelect2 {
  grid-row-start: 1;
  grid-row-end: 1;
  width: 80%;
}

.tableitem {
  padding-right: 30px;
  padding-left: 10px;
  border: none !important;
  min-width: 350px;
  padding-bottom: 20px;
  font-size: 18px !important;
  display: inline-block;
}

.tableitemdetail {
  font-size: 18px !important;
  padding-left: 3px;
}

.projectName {
  margin-left: 10px;
  font-size: 26px !important;
}

.nfa-panel {
  margin-left: 15px;
}

.toggle-for-recommendations {
  align-self: self-end;
}

.default-value-reference {
  font-style: italic;
}

mark {
  outline: 1px solid red;
  font-weight: bold;
}
