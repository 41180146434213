.mat-mdc-form-field {
  margin-bottom: 18px;
}
.form-field--with-margin {
  margin-bottom: 27px;
}

.mat-mdc-optgroup-label .mdc-list-item__primary-text {
  font-weight: bold;
}
