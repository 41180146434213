//$msg-color-main: #A01441;
$msg-color-main: #A01441;
$msg-color-main-01: #710e2e;
$msg-color-main-02: #710e2eE6;

//$msg-color-gray: #6f6f6f;
$msg-color-gray: #6f6f6f;
$msg-color-gray-01: #f2f2f2;
$msg-color-gray-02: #e5e5e5;
$msg-color-gray-03: #cecece;
$msg-color-gray-04: #D8D8D8;
$msg-color-gray-05: #595959;

//$msg-color-accent: #56A3BC;
$msg-color-accent: #56A3BC;

$color-search: orange;

$msg-color-green: #13a057;
$msg-color-green-01: #008000;
$msg-color-red: #c41830;
$msg-color-red-01: #FF0000;

//neue Farben
$msg-color-main-text-dark: #171717;
$msg-color-main-text-light: #ffffff;

$msg-color-main-background-light: #ffffff;

$msg-color-main-border-dark: #171717;
$msg-color-main-border-light: #ffffff;

$msg-color-success: #13a057;
$msg-color-warning: #db9d1a;
$msg-color-error: #c41830;
$msg-color-state-info: #1385a0;
$msg-color-state-info-background: #1385a033;
$msg-color-disabled-background: #6f6f6f99;
