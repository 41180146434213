@import "src/scss/1_settings/color-constants";

mat-expansion-panel-header {
  height: 15% !important;
  padding: 10px 20px !important;
}

.panelDescriptionIconMissing {
  // scss - class naming -- Nr. 18
  width: 50px !important;
  height: 30px !important;
  font-size: 30px !important;
}

.panelDescriptionIconDone {
  // scss - class naming -- Nr. 19
  width: 50px !important;
  height: 30px !important;
  font-size: 30px !important;
}

.mat-action-row {
  border: 0 solid !important;
}

mat-panel-title {
  min-width: 250px;
  max-width: 250px;
}

mat-panel-description {
  display: block !important;

  span {
    display: block;
    text-align: left;
  }
}

mat-expansion-panel {
  border: 1px solid $msg-color-accent;
  border-bottom: 2px solid $msg-color-accent;
}

.firstPanel {
  // scss - class naming -- Nr. 21
  border-top: 2px solid;
}

.mat-expansion-panel.mat-expanded {
  border-left: 2px solid;
  border-right: 2px solid;
}

.dialogTitleBox {
  // scss - class naming -- Nr. 23
  display: grid;
  grid-template-columns: 60% 40%;
}

.dialogHeadlineLeft {
  // scss - class naming -- Nr. 24
  grid-column-start: 1;
  grid-column-end: 1;
  text-align: left;
}

.dialogHeadlineRight {
  // scss - class naming -- Nr. 25
  grid-column-start: 2;
  grid-column-end: 2;
  text-align: right;
  padding-top: 5px;
  display: flex;
}

.dialogListBox {
  // scss - class naming -- Nr. 26
  display: grid;
  grid-template-columns: 50% 50%;
  padding-bottom: 20px;
}

.dialogListLeft {
  // scss - class naming -- Nr. 27
  grid-column-start: 1;
  grid-column-end: 1;
  text-align: left;
}

.dialogListRight {
  // scss - class naming -- Nr. 28
  grid-column-start: 2;
  grid-column-end: 2;
  text-align: left;
}

.stakeholderTitleBox {
  // scss - class naming -- Nr. 34
  display: grid;
  grid-template-columns: 75% 25%;
  padding-bottom: 20px;
}

.stakeholderHeadlineLeft {
  // scss - class naming -- Nr. 35
  grid-column-start: 1;
  grid-column-end: 1;
  text-align: left;
}

.stakeholderHeadlineRight {
  // scss - class naming -- Nr. 36
  grid-column-start: 2;
  grid-column-end: 2;
  text-align: right;
  padding-top: 10px;
  display: flex;
}

.testMethodsPanelTitle {

  // scss - class naming -- Nr. 38
  mat-panel-title {
    min-width: 100%;
    max-width: 100%;
  }
}

.catalogTitleBox {
  // scss - class naming -- Nr. 41
  display: grid;
  grid-template-columns: 75% 25%;
  padding-bottom: 20px;

  .catalogTitleLeftBox {
    // scss - class naming -- Nr. 42
    grid-column-start: 1;
    grid-column-end: 1;
    text-align: left;
  }

  .catalogTitleRightBox {
    // scss - class naming -- Nr. 43
    grid-column-start: 2;
    grid-column-end: 2;
    text-align: right;
    padding-top: 10px;
    display: flex;
  }
}

.catalogBox {
  // scss - class naming -- Nr. 44
  display: grid;
  grid-template-columns: 25% 75%;
  grid-gap: 10px;
  margin: 10px;

  .catalogBoxLeftFirst {
    // scss - class naming -- Nr. 45
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
    text-align: left;
  }

  .catalogBoxRightFirst {
    // scss - class naming -- Nr. 46
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    text-align: justify;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  .catalogBoxLeftSecond {
    // scss - class naming -- Nr. 47
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
    text-align: left;
  }

  .catalogBoxRightSecond {
    // scss - class naming -- Nr. 48
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
    text-align: justify;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  .catalogBoxLeftThird {
    // scss - class naming -- Nr. 49
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
    text-align: left;
  }

  .catalogBoxRightThird {
    // scss - class naming -- Nr. 50
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
    text-align: justify;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  .catalogBoxLeftFourth {
    // scss - class naming -- Nr. 51
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 4;
    grid-row-end: 4;
    text-align: left;
  }

  .catalogBoxRightFourth {
    // scss - class naming -- Nr. 52
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 4;
    text-align: justify;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  .catalogBoxLeftFifth {
    // scss - class naming -- Nr. 53
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 5;
    grid-row-end: 5;
    text-align: left;
  }

  .catalogBoxRightFifth {
    // scss - class naming -- Nr. 54
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 5;
    text-align: justify;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}

.catalogContentBox {
  // scss - class naming -- Nr. 55
  display: grid;
  grid-template-columns: 70px auto;
  grid-gap: 10px;

  .catalogContentBoxLeft {
    // scss - class naming -- Nr. 56
    grid-column-start: 1;
    grid-column-end: 1;
  }

  .catalogContentBoxRight {
    // scss - class naming -- Nr. 57
    grid-column-start: 2;
    grid-column-end: 2;
  }
}

mat-sidenav-container {
  min-width: min-content;
  display: flex;

  .mat-drawer-inner-container {
    overflow: hidden;
    width: 420px;
    height: auto !important;
    background-color: $msg-color-gray;
  }

  mat-sidenav {
    padding-top: 60px;

    ul {
      margin: 0;
    }

    .menuButton {
      // scss - class naming -- Nr. 58
      display: flex;
      position: absolute;
      right: 0;
    }

    .mat-mdc-action-list .mat-mdc-list-item {
      height: 40px;
    }
  }

}

.cardForApplication {
  margin: 20px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.mat-grid-tile .mat-figure {
  display: inline-block !important;
}

.nav-left {
  background-color: $msg-color-main-background-light;
  width: 400px;
  max-width: 400px;
  min-width: 320px;
  margin-right: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;

}


.mat-sidenav-container {
  background-color: $msg-color-main-background-light  !important;
  height: 100%;
}

.mat-nav-list {
  padding-top: 0;
}

.header-style {
  font-size: 30px;
  color: $msg-color-main;
}

.header-style2 {
  margin-left: 15px;
  margin-top: 5px;
  font-size: 18px;
}

.chavron-icon {
  margin-left: 10px;
  flex: 1 1 0;
  box-sizing: border-box;
  font-size: 23px;
}

.comment-field-nfa {
  width: 94%;
  min-height: 100px !important;
  margin-right: 20px;
  margin-left: 40px;
}

.requiredPosition {
  text-align: right;
  margin: 0;
}

.chipsInputLineContent {
  text-align: center;
}

.chipsInputLine {
  width: 97%;
}

.expandIcon {
  position: unset !important;
  top: unset !important;
  transform: unset !important;
}
