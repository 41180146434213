@import '../1_settings/color-constants';
@import '../1_settings/global-constants';

.context-menu-item {
  border: 2px solid $msg-color-main-background-light !important;
  color: $msg-color-main-text-dark;
  > mat-icon {
    color: $msg-color-main-text-dark
  }

  &:focus-visible, &:hover {
    border: 2px solid $msg-color-main !important;
    color: $msg-color-main;
    > mat-icon {
      color: $msg-color-main
    }
  }

  &--disabled, &--disabled:focus-visible, &--disabled:hover  {
    cursor: not-allowed;
    border: 2px solid $msg-color-main-background-light !important;
    color: $msg-color-gray;
    > mat-icon {
      color: $msg-color-gray
    }
  }
}
