.applicationsToggleButtonText { // scss - class naming -- Nr. 10
  font-family: 'Akkurat regular';
  font-size: 16px;
}

.mat-dialog-container {
  font-family: 'Akkurat light';
  font-size: 16px;
}

.catalogBox { // scss - class naming -- Nr. 44

  .catalogBoxLeftFirst {  // scss - class naming -- Nr. 45
    font-family: 'Akkurat regular';
  }
  .catalogBoxRightFirst { // scss - class naming -- Nr. 46
    text-align: justify;
  }
  .catalogBoxLeftSecond { // scss - class naming -- Nr. 47
    font-family: 'Akkurat regular';
  }
  .catalogBoxRightSecond {  // scss - class naming -- Nr. 48
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
    text-align: left;
  }
  .catalogBoxLeftThird {  // scss - class naming -- Nr. 49
    font-family: 'Akkurat regular';
  }
  .catalogBoxRightThird { // scss - class naming -- Nr. 50
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
    text-align: left;
  }
  .catalogBoxLeftFourth { // scss - class naming -- Nr. 51
    font-family: 'Akkurat regular';
  }
  .catalogBoxRightFourth {  // scss - class naming -- Nr. 52
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 4;
    text-align: left;
  }
  .catalogBoxLeftFifth {  // scss - class naming -- Nr. 53
    font-family: 'Akkurat regular';
  }
  .catalogBoxRightFifth { // scss - class naming -- Nr. 54
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 5;
    text-align: left;
  }
}
.centered-img {
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
  }
}
ul.checklist li {
  list-style-type: none;
  position: relative;
  line-height: 19.5px;
  padding-left: 30px;
}
ul.checklist li:before {
  font-family: "Material Icons";
  content: "\e5ca";
  position: absolute;
  font-size: 25px;
  left: 0;
}
