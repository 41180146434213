@import "src/scss/1_settings/color-constants";
@import "src/scss/1_settings/breakpoints";

.mat-mdc-dialog-container {
  min-width: 750px !important;
  border-radius: 5px !important;
  border: 2px solid;

  @include breakpoint-max($large-tablet) {
    min-width: 300px !important;
  }
}

.mdc-dialog .mdc-dialog__content {
  padding: 0 24px 0 24px;
}

.information-dialog-container .mat-mdc-dialog-container {
  padding: 0;
  border-radius: 5px !important;
  min-width: auto !important;
}

.confirmation-dialog-container .mat-mdc-dialog-container {
  padding: 0;
  border-radius: 5px !important;
  max-width: 80vw;
  width: 780px;
  //width: 80vw;
}

.pruefgegenstand-dialog-container .mat-mdc-dialog-container {
  min-width: auto !important;
}

.mdc-dialog__actions {
  padding-right: 24px !important;
  padding-bottom: 10px !important;
}

.header-message {
  display: inline !important;
  font-size: large !important;
  color: $msg-color-main !important;
  margin: 0 !important;
  padding: 0 !important;
}
