@use '@angular/material' as mat;
@import 'src/scss/1_settings/color-constants';

.application {
  &__card {
    margin: 10px;
    padding: 16px;

    @include mat.elevation(0);

    &--halfway {
      flex: 50%;
    }

    &--no-top-bottom-margin {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &__button {
    background-color: $msg-color-main-background-light;
    color: $msg-color-gray;
  }
}

.stakeholder-overview{
  &__error-message{
    font-size: 75%;
    color: #f44336;
    margin-top: 0.6666666667em;
  }
}
