@import '../1_settings/color-constants.scss';

.material-icons-red {
  color: $msg-color-main;
}
.mat-icon {
  overflow: unset !important;
  vertical-align: middle;
  height: 26px !important;
}
