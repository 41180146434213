@import "src/scss/1_settings/color-constants";

nfa-save-confirm-snackbar {
  color: #000 !important;
  font-family: "Akkurat light";
}

.mat-mdc-snack-bar-container {
  margin: 8px auto 50px auto !important;
}

mat-snack-bar-container .mdc-snackbar__surface {
  background-color: $msg-color-main-background-light !important;
  border-radius: 5px !important;
  border: 1px solid $msg-color-red !important;
  margin: auto !important;
  padding: 0;
  width: auto !important;

  .mat-mdc-snack-bar-label {
    padding: 9px !important;
  }

  .snackbar-message {
    padding: 3px 0px !important;
  }

  .mat-snack-bar-content-container {
    display: flex;
  }

  mat-dialog-actions {
    padding: 0 !important;
    min-height: auto !important;

    #closeButton {
      padding: 0 0 0 10px;
      background-color: transparent;
      border: none;
      font-family: "Material Icons";
      float: right;
    }
  }
}

