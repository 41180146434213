@import '../1_settings/layout-constants';

body {
  padding: 0;
  margin: $body-margin;
}

html {
  background-color: $msg-color-main-background-light;
}
