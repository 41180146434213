@use '@angular/material' as mat;
@import '@angular/material/theming';

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $font-family: 'Akkurat light',
  $headline-5: mat.define-typography-level(32px, 48px, 700),
  $body-2: mat.define-typography-level(16px, 24px, 500),
  $body-1: mat.define-typography-level(16px, 24px, 500)
);

// Override the typography in the core CSS.
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-component-typographies($custom-typography);
@include mat.core();

@font-face {
  font-family: Akkurat light;
  src: url("../../assets/fonts/AkkuratPro-Light.otf") format("opentype");
}

@font-face {
  font-family: Akkurat regular;
  src: url("../../assets/fonts/AkkuratPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: Akkurat bold;
  src: url("../../assets/fonts/AkkuratPro-Bold.otf") format("opentype");
}
