@import "../1_settings/color-constants";
@import '../1_settings/global-constants';

h1 {
  color: $msg-color-main !important;
}

h2 {
  color: $msg-color-main !important;
}

h3 {
  color: $msg-color-main !important;
}

.highlight-search { // scss - class naming -- Nr. 11
  background: $msg-color-warning;
}

.searchInput { // scss - class naming -- Nr. 1
  mat-icon {
    color: $msg-color-accent;
  }
}

th {
  color: $msg-color-main-text-light !important;
  background-color: $msg-color-accent !important;
}

td {
  color: $msg-color-main-text-dark !important;
}

.mat-sort-header-arrow {
  color: $msg-color-main-text-light;
}

.mat-mdc-row:nth-child(odd) {
  background-color: $msg-color-main-background-light;
}

.mat-mdc-row:nth-child(even) {
  background-color: $msg-color-gray-02;
}

.mat-mdc-row:nth-child(odd):hover, .mat-mdc-row:nth-child(even):hover {
  background-color: $msg-color-gray-03 !important;
}

.mat-mdc-paginator-container {
  color: $msg-color-main-text-dark;
  background-color: $msg-color-main-background-light;
  border-left: $default-border;
  border-right: $default-border;
  border-bottom: $default-border;
}

.mat-mdc-paginator-icon {
  color: $msg-color-main-text-dark;
}

.icon-done { // scss - class naming -- Nr. 12
  color: $msg-color-success;
}

.accent {
  color: $msg-color-main;
}

@media print {
  li {
    color: $msg-color-main-text-dark;
    font-family: "Akkurat light", sans-serif;

    span {
      font-family: "Akkurat light", sans-serif;
    }

    a {
      font-family: "Akkurat light", sans-serif;
    }
  }
  ul li::before, ul li::marker, ol li::before, ol li::marker {
    color: $msg-color-main-text-dark;
  }
}

@media screen {
  li {
    color: $msg-color-main-text-dark;
    font-family: "Akkurat light", sans-serif;

    span {
      font-family: "Akkurat light", sans-serif;
    }

    a {
      font-family: "Akkurat light", sans-serif;
    }

    &::before {
      color: $msg-color-main;
    }

    &::marker {
      color: $msg-color-main;
    }
  }
  ul li::before, ul li::marker {
    color: $msg-color-main;
  }
  ol li::before, ol li::marker {
    font-family: "Akkurat regular", sans-serif;
    color: $msg-color-main;
  }
}


.mat-mdc-dialog-container {
  background-color: $msg-color-main-background-light;
  border-color: $msg-color-main !important;
}

.panelDescriptionInputMissing {
  // scss - class naming -- Nr. 17
  color: $msg-color-error;
}

.panelDescriptionInputDone {
  // scss - class naming -- Nr. 20
  color: $msg-color-main-text-dark;
}

.panelDescriptionIconMissing {
  // scss - class naming -- Nr. 18
  color: $msg-color-error;
}

.panelDescriptionIconDone {
  // scss - class naming -- Nr. 19
  color: $msg-color-success;
}

mat-expansion-panel {
  border-color: $msg-color-accent;

  .mat-expansion-indicator::after {
    border-color: $msg-color-accent;
  }
}

.firstPanel {
  // scss - class naming -- Nr. 21
  border-color: $msg-color-accent !important;
}

.mat-expansion-panel.mat-expanded {
  border: 1px solid $msg-color-accent !important;

  .mat-expansion-panel-header {
    border-bottom: 1px solid $msg-color-gray-02;
  }
}

.mat-pseudo-checkbox-checked {
  background: $msg-color-main !important;
}

.singleCheckbox {
  // scss - class naming -- Nr. 33
  .mat-checkbox-checked .mat-checkbox-background {
    background: $msg-color-main;
  }[ng-reflect-model] {
     background-color: $msg-color-main !important;
   }
}

.catalogContentBox {
  // scss - class naming -- Nr. 55
  .catalogContentBoxLeft {
    // scss - class naming -- Nr. 56
    background-color: $msg-color-gray-03;
  }
}

.cardForApplication {
  background-color: transparent;
}

.mat-chip.mat-standard-chip {
  background-color: $msg-color-main-background-light;
  border: 3px solid $msg-color-accent;
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: $msg-color-accent;
  opacity: 1;
}

