@import "src/scss/1_settings/color-constants";
.flex-container {
  padding: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.application-card {
  flex: 0 0 400px;
  height: auto;
  background-color: rgba(211, 211, 211, 0.5);

  .mat-mdc-card-title {
    font-weight: bold;
  }

  &:hover {
    background-color: $msg-color-main-02;
  }

  &__content {
    font-size: 18px !important;
    padding-left: 3px;
    padding-right: 30px;
    display: block;
    border: none !important;

    &--small {
      padding-left: 3px;
      padding-right: 30px;
      display: block;
      border: none !important;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}
